<template>
  <div class="mb-1">
    <b-row>
      <b-col md="6">
        <div class="d-flex">
          <b-avatar
              v-if="workFlo.company.avatar && workFlo.company.avatar.url"
              size="lg"
              :src="workFlo.company.avatar.url"
          />
          <b-avatar
              v-else
              size="lg"
              :text="initials(workFlo.company.name)"
          />

          <div class="my-auto ml-1 text-truncate">
            <b>{{ workFlo.company.name }}</b>
          </div>

        </div>
      </b-col>
      <b-col md="6">
        <div class="d-flex h-100">
          <div class="my-auto w-100 text-truncate text-right">
            {{ capitalize(moment(workFlo._history.nextAction).from()) }}
          </div>

          <div class="my-auto ml-1">
            <vue-apex-charts
                type="radialBar"
                height="30"
                width="30"
                :options="progressChartOptions"
                :series="progressChartSeries"
                v-b-tooltip.hover.top="status.text"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { initials }             from '../../utils/utils'
import { capitalize }           from '../../utils/filter'

import moment        from 'moment'
import VueApexCharts from 'vue-apexcharts'
import i18n          from '../../libs/i18n'

export default {
  components: { VueApexCharts },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const progressChartOptions = ref({
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['rgba(40,199,111,0.85)', 'rgba(234,84,85,0.85)'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      fill: {
        colors: [function ({ value, seriesIndex, w }) {
          if (value < 25) {
            return 'rgba(40,199,111,0.85)'
          } else if (value < 50) {
            return 'rgba(255,159,67,0.85)'
          } else {
            return 'rgba(234,84,85,0.85)'
          }
        }]
      },
      stroke: {
        lineCap: 'round',
      },
    })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const status = computed(() => {
      if (props.workFlo._history.status == 'no action') {
        let moreRecent = moment(props.workFlo._history.history[0].date) > moment(props.workFlo._history.lastAction) ? props.workFlo._history.history[0].date : props.workFlo._history.lastAction
        // let moreRecent = (props.workFlo._history.nextAction != null && moment(props.workFlo._history.nextAction)>moment())?moment(props.workFlo._history.nextAction):(moment(props.workFlo._history.history[0].date) > moment(props.workFlo._history.lastAction)?props.workFlo._history.history[0].date:props.workFlo._history.lastAction)

        if (moreRecent == null) {
          moreRecent = props.workFlo._history.history[0].date
        }

        return {
          icon: 'info-circle',
          variant: 'warning',
          // text: capitalize(i18n.t('noActionFor')) + ' ' + moment(props.workFlo._history.history[0].date).toNow(true)
          text: capitalize(i18n.t('noActionFor')) + ' ' + moment(moreRecent).toNow(true)
        }
      } else if (props.workFlo._history.status == 'late') {
        if (props.workFlo._history.nextAction) {
          return {
            icon: 'bell',
            variant: 'danger',
            text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.nextAction).toNow(true)
            // text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.lastAction).toNow(true)
          }
        } else {

          return {
            icon: 'bell',
            variant: 'danger',
            // text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.taskList[0].date).toNow(true)
            text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.lastAction).toNow(true)
          }
        }
      } else {
        return {
          icon: 'history',
          variant: 'secondary',
          text: capitalize(i18n.t('nextActionIn')) + ' ' + moment(props.workFlo._history.nextAction).fromNow(true)
        }
      }
    })

    const progressChartSeries = computed(() => {
      let lastAction = props.workFlo._history.lastAction
      let nextAction = props.workFlo._history.nextAction

      if (nextAction != null && moment(nextAction) >= moment()) {
        return [0]
      } else {
        if (moment(lastAction) >= moment()) {
          return [0]
        } else {
          let diffDays = moment().diff(moment(lastAction), 'days')

          let days = props.workFlo.status.delayDay

          if (lastAction == null || diffDays >= days) {
            // console.log(props.workFlo.title, 100)
            return [100]
          } else if (diffDays <= 0) {
            // console.log(props.workFlo.title, 0)
            return [0]
          } else {
            // console.log(props.workFlo.title, parseInt((diffDays*100)/days))
            return [parseInt((diffDays * 100) / days)]
          }
        }
      }

    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,

      // Data
      progressChartOptions,

      // Computed
      status,
      progressChartSeries,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize, initials },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>