<template>
  <div>
    <main-indicators
        :main-indicators="dashboard.mainIndicators"
        :indicator-choice.sync="indicatorChoice"
    />

    <b-row>
      <filter-details
          :filters.sync="localFilters"
          :available-filters="availableFilters"
          class="mt-50"
      />
    </b-row>

    <b-row>
      <b-col md="4">
        <estimated-turnover
            :datas="estimatedTurnover"
        />
      </b-col>
      <b-col md="4">
        <customer-work-flos
            :datas="customerWorkFlos"
        />
      </b-col>
      <b-col md="4">
        <late-work-flos
            :datas="lateWorkFlos"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <orders
            :datas="orders"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="8">
        <b-card>
          <template
              #header
              class="x"
          >
            <div style="flex: 1 1 auto; min-height: 1px;">
              <h4
                  class="card-title"
                  style="margin-bottom: 1.53rem;margin-bottom: 0;"
              >{{ indicatorTitle }}</h4>
              <!--              <h6-->
              <!--                  class="card-subtitle text-muted"-->
              <!--              >Jours restant avant les suivis</h6>-->
            </div>

            <!--            <div class="w-100 d-block">-->
            <!--              <duration-form-->
            <!--                  :duration-start="durationStart"-->
            <!--                  :duration-end="durationEnd"-->
            <!--                  :comparison-duration-start="comparisonDurationStart"-->
            <!--                  :comparison-duration-end="comparisonDurationEnd"-->

            <!--                  @updateDates="updateDates"-->
            <!--              />-->
            <!--            </div>-->
          </template>

          <indicator-choice
              :indicator-choice.sync="indicatorChoice"
          />

          <acquisition-indicator
              v-if="indicatorChoice== 'acquisition'"
              :acquisition-indicators="dashboard.acquisition"
          />

          <actions-indicator
              v-if="indicatorChoice== 'actions'"
              :actions-indicator="dashboard.actions"
              :is-ready="isReady"
          />
        </b-card>

      </b-col>

      <b-col md="4">
        <work-flos
            v-if="indicatorChoice== 'acquisition' || indicatorChoice== 'actions'"
            :work-flos="dashboard.workFlos"
            :start="periodStart"
            class="mb-2"
        />

        <work-flos-monitoring
            v-if="indicatorChoice== 'acquisition' || indicatorChoice== 'actions'"
            :work-flos-monitoring="dashboard.workFlosMonitoring"
            :financial-year-start="new Date('2023-07-01')"
            :is-ready="isReady"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import store from '../../../store'

import MainIndicators                                        from './components/main/MainIndicators.vue'
import WorkFlos                                              from './components/main/WorkFlos.vue'
import WorkFlosMonitoring                                    from './components/main/WorkFlosMonitoring.vue'
import EstimatedTurnover                                     from './components/main/EstimatedTurnover.vue'
import CustomerWorkFlos                                      from './components/main/CustomerWorkFlos.vue'
import LateWorkFlos                                          from './components/main/LateWorkFlos.vue'
import Orders                                                from './components/main/Orders.vue'

import moment                                                from 'moment'
import useAPI                                                from '../../../utils/useAPI'
import AcquisitionIndicator                                  from './components/main/AcquisitionIndicator.vue'
import IndicatorChoice                                       from './components/main/IndicatorChoice.vue'
import { capitalize }                                        from '../../../utils/filter'
import i18n                                                  from '../../../libs/i18n'
import ActionsIndicator                                      from './components/main/ActionsIndicator.vue'
import DurationForm                                          from './components/main/DurationForm.vue'
import { getCurrentFinancialYear, getPreviousFinancialYear } from '../../../utils/utils'
import FilterDetails                                         from '../../../components/base3/header/FilterDetails.vue'

export default {
  components: {
    FilterDetails,
    DurationForm,
    ActionsIndicator,
    AcquisitionIndicator,
    WorkFlos,
    MainIndicators,
    WorkFlosMonitoring,
    IndicatorChoice,
    EstimatedTurnover,
    CustomerWorkFlos,
    LateWorkFlos,
    Orders
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const indicatorChoice = ref('acquisition')
    const dashboard = ref({
      mainIndicators: {}
    })
    const isReady = ref(false)
    const durationStart = ref(getCurrentFinancialYear().start)
    const durationEnd = ref(getCurrentFinancialYear().end)
    const comparisonDurationStart = ref(getPreviousFinancialYear().start)
    const comparisonDurationEnd = ref(getPreviousFinancialYear().end)

    const localFilters = ref([{
      sectionLogicOperator: 'AND',
      sectionFilters: [
        {
          logicOperator: 'AND',
          field: '_dashboardPeriod',
          operator: 'between',
          // value: ['2023-07-01', '2024-06-30']
          value: [durationStart.value, durationEnd.value],
          _sectionOperatorDisabled: true,
          _fieldDisabled: true,
          _allowDelete: false
        }
      ]
    },
      {
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: '_dashboardComparativePeriod',
            operator: 'between',
            // value: ['2022-07-01', '2023-06-30']
            value: [comparisonDurationStart.value, comparisonDurationEnd.value],
            _sectionOperatorDisabled: true,
            _fieldDisabled: true,
            _allowDelete: false
          }
        ]
      }])
    const estimatedTurnover = ref({})
    const customerWorkFlos = ref({})
    const lateWorkFlos = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const indicatorTitle = computed(() => {
      if (indicatorChoice.value == 'acquisition') {
        return capitalize(i18n.t('mainIndicators'))
      } else {
        return ''
      }
    })

    const availableFilters = computed(() => {
      return store.getters['workFlo/getAvailableFilters'].concat([
        {
          field: '_dashboardPeriod',
          i18n: capitalize(i18n.t('period')),
          subFields: [],
          type: 'date',
        },
        {
          field: '_dashboardComparativePeriod',
          i18n: capitalize(i18n.t('comparison')),
          subFields: [],
          type: 'date',
        },
      ])
    })

    const periodStart = computed(() => {
      return new Date(localFilters.value[0].sectionFilters.find(sf => sf.field == '_dashboardPeriod').value[0])
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(indicatorChoice, val => {
      isReady.value = false
      if (val == 'acquisition') {
        acquisitionRequest()
      } else if (val == 'actions') {
        actionsRequest()
      }
    })

    watch(localFilters, val => {
      acquisitionRequest()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateDates = ({ start, end, comparisonStart, comparisonEnd }) => {
      // console.log(start, end, comparisonStart, comparisonEnd)
      durationStart.value = start
      durationEnd.value = end
      comparisonDurationStart.value = comparisonStart
      comparisonDurationEnd.value = comparisonEnd

      if (indicatorChoice.value == 'acquisition') {
        acquisitionRequest()
      } else if (indicatorChoice.value == 'actions') {
        actionsRequest()
      }
    }

    const estimatedTurnoverRequest = () => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'estimatedTurnover',
        filters: localFilters.value
      })
          .then(response => {
            estimatedTurnover.value = response.data.results
          })
    }

    const customerWorkFlosRequest = () => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'customerWorkFlos',
        filters: localFilters.value
      })
          .then(response => {
            customerWorkFlos.value = response.data.results
          })
    }

    const lateWorkFlosRequest = () => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'lateWorkFlos',
        filters: localFilters.value
      })
          .then(response => {
            lateWorkFlos.value = response.data.results
          })
    }

    const ordersRequest = () => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'orders',
        filters: localFilters.value
      })
          .then(response => {
            orders.value = response.data.results
          })
    }

    const acquisitionRequest = () => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'acquisition',
        filters: localFilters.value
      })
          .then(response => {
            dashboard.value = response.data.results
            isReady.value = true

          })
    }

    const actionsRequest = () => {
      store.dispatch('workFlo/getDashboard', {
        indicatorType: 'actions',
      })
          .then(response => {
            dashboard.value = response.data.results
            isReady.value = true
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    estimatedTurnoverRequest()
    customerWorkFlosRequest()
    lateWorkFlosRequest()
    ordersRequest()
    // acquisitionRequest()

    return {
      // Components
      moment,

      // Data
      indicatorChoice,
      dashboard,
      isReady,
      durationStart,
      durationEnd,
      comparisonDurationStart,
      comparisonDurationEnd,
      localFilters,
      availableFilters,
      estimatedTurnover,
      customerWorkFlos,
      lateWorkFlos,
      orders,

      // Computed
      indicatorTitle,
      periodStart,

      // Methods
      updateDates,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>