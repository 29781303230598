<template>
  <div class="d-flex overflow-hidden mb-4">

    <div
        class="border d-flex rounded rounded-lg text-center mr-2 cursor-pointer"
        :class="localIndicatorChoice == 'acquisition'?'border-primary':''"
        style="width: 110px; height: 94px;"
        :style="localIndicatorChoice != 'acquisition'?'border-style: dashed!important;':''"
        @click="localIndicatorChoice = 'acquisition'"
    >
      <div class="w-100 h-100 d-flex">
        <div class="m-auto">
          <b-avatar
              size="34"
              :variant="localIndicatorChoice == 'acquisition'?'light-primary':'light-secondary'"
          >
            <icon
                icon="shopping-cart"
                size="lg"
            />
          </b-avatar>
          <h4 class="mt-50">Acquisition</h4>
        </div>

      </div>
    </div>

    <div
        class="border d-flex rounded rounded-lg text-center mr-2 cursor-pointer"
        :class="localIndicatorChoice == 'clients'?'border-primary':''"
        style="width: 110px; height: 94px;"
        :style="localIndicatorChoice != 'clients'?'border-style: dashed!important;':''"
        @click="localIndicatorChoice = 'clients'"
    >
      <div class="w-100 h-100 d-flex">
        <div class="m-auto">
          <b-avatar
              size="34"
              :variant="localIndicatorChoice == 'clients'?'light-primary':'light-secondary'"
          >
            <icon
                icon="chart-pie"
                size="lg"
            />
          </b-avatar>
          <h4 class="mt-50">Clients</h4>
        </div>

      </div>
    </div>

    <div
        class="border d-flex rounded rounded-lg text-center mr-2 cursor-pointer"
        :class="localIndicatorChoice == 'actions'?'border-primary':''"
        style="width: 110px; height: 94px;"
        :style="localIndicatorChoice != 'actions'?'border-style: dashed!important;':''"
        @click="localIndicatorChoice = 'actions'"
    >
      <div class="w-100 h-100 d-flex">
        <div class="m-auto">
          <b-avatar
              size="34"
              :variant="localIndicatorChoice == 'actions'?'light-primary':'light-secondary'"
          >
            <icon
                icon="chart-pie"
                size="lg"
            />
          </b-avatar>
          <h4 class="mt-50">Actions</h4>
        </div>

      </div>
    </div>

    <div
        class="border d-flex rounded rounded-lg text-center cursor-pointer"
        style="width: 110px; height: 94px; border-style: dashed!important;"
    >
      <div class="w-100 h-100 d-flex">
        <div class="m-auto">
          <b-avatar
              size="34"
              variant="light-secondary"
          >
            <icon
                icon="plus"
                size="lg"
            />
          </b-avatar>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    indicatorChoice: {
      type: String,
      default: 'indicatorChoice'
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const localIndicatorChoice = computed({
      get () {
        return props.indicatorChoice
      },
      set (val) {
        emit('update:indicatorChoice', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      localIndicatorChoice,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>