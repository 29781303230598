import { render, staticRenderFns } from "./CrmIndicators.vue?vue&type=template&id=71253baa&scoped=true&"
import script from "./CrmIndicators.vue?vue&type=script&lang=js&"
export * from "./CrmIndicators.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71253baa",
  null
  
)

export default component.exports