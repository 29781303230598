<template>
  <div>
    <b-card>
      <b-overlay
          :show="!isReady"
          rounded="sm"
      >
        <b-row v-if="isReady">
          <b-col md="7">
            <h1>{{ capitalize($t('workFlos')) }}</h1>
            <p>{{ capitalize($t('sinceThe', { since: moment(start).format("LL") })) }}</p>

            <div>
              <h2>{{ workFlos.currentFinancialYearLeads }}</h2>
            </div>

            <indicator-evolution
                :indicator="workFlos.difference"
            />

          </b-col>

          <b-col md="5">
            <vue-apex-charts
                height="120"
                :options="chartOptions"
                :series="series"
            />
          </b-col>

        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../utils/filter'
import { isEmptyObject }        from '../../../../../utils/utils'

import IndicatorEvolution from '../../../../../components/indicatorEvolution/IndicatorEvolution.vue'

import moment        from 'moment'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    IndicatorEvolution,
    VueApexCharts
  },
  props: {
    workFlos: {
      type: Object,
      default: () => {}
    },
    start: {
      type: Date
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.workFlos)
    })

    const series = computed(() => {
      return Object.values(props.workFlos.chart)
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        // comparedResult: [2, -3, 8],
        labels: Object.keys(props.workFlos.chart),
        stroke: { width: 0 },
        // colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            startAngle: -10,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  // formatter (val) {
                  //   // eslint-disable-next-line radix
                  //   return `${parseInt(val)}%`
                  // },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  // label: 'App',
                  // formatter () {
                  //   return '53%'
                  // },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,

      // Data
      // localFinancialYearStart,

      // Computed
      isReady,
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>