<template>
  <div>
    <b-overlay
        :show="!isReady"
        rounded="sm"
    >
      <div v-if="isReady">

        <div>
          <b>{{
              capitalize($t('totalConversionRate', { number: percent(acquisitionIndicators.totalConversionRate.value, 0) }))
             }}
          </b>
          <span class="ml-50">
            <small>
              <indicator-evolution
                  :indicator="acquisitionIndicators.totalConversionRate.difference"
              />
            </small>
          </span>
        </div>
        <div>
          <b>{{
              capitalize($t('totalConversionTime', { number: acquisitionIndicators.totalConversionTime.value + ' ' + $t('d') }))
             }}
          </b>
          <span class="ml-50">
            <small>
              <indicator-evolution
                  :indicator="acquisitionIndicators.totalConversionTime.difference"
              />
            </small>
          </span>
        </div>

        <vue-apex-charts
            type="line"
            height="350"
            class="mt-1"
            :options="chartOptions"
            :series="series"
        ></vue-apex-charts>

      </div>
    </b-overlay>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, percent }  from '../../../../../utils/filter'
import { isEmptyObject }        from '../../../../../utils/utils'

import VueApexCharts from 'vue-apexcharts'

import IndicatorEvolution from '../../../../../components/indicatorEvolution/IndicatorEvolution.vue'
import i18n               from '../../../../../libs/i18n'

export default {
  components: {
    IndicatorEvolution,
    VueApexCharts
  },
  props: {
    acquisitionIndicators: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.acquisitionIndicators)
    })

    const series = computed(() => {
      let acquisitions = []
      let times = []
      for (const status in props.acquisitionIndicators.chart) {
        acquisitions.push(props.acquisitionIndicators.chart[status].newWF.value)
        times.push(props.acquisitionIndicators.chart[status].cumulativeTime.value)
      }

      return [{
        name: capitalize(i18n.tc('acquisition', 2)),
        type: 'bar',
        data: acquisitions
      }, {
        name: capitalize(i18n.t('time')),
        type: 'line',
        data: times
      }]

    })

    const labels = computed(() => {
      return Object.keys(props.acquisitionIndicators.chart)
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0
          }
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        colors: ['#7367F029', '#FF4961BF'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            // console.log(val, opts)
            // return val
            return displayDataLabel(opts.seriesIndex, opts.dataPointIndex)
            // return val + '%'
          },
          offsetY: -10,
          style: {
            fontSize: '12px',
            // colors: ['#7367F0', '#FF4961']
            colors: ['black', 'black']
          },
          background: {
            enabled: false,
          }

        },
        labels: labels.value,
        markers: {
          size: 0
        },
        // xaxis: {
        //   type: 'datetime'
        // },
        yaxis: [
          {
            seriesName: 'TEAM A',
            // title: {
            //   text: 'TEAM A',
            //   style: {
            //     color: '#7367F0',
            //   },
            // },
            labels: {
              style: {
                colors: '#7367F0'
              }
            }
          },
          {
            seriesName: 'TEAM B',
            opposite: true,
            // title: {
            //   text: 'TEAM B',
            //   style: {
            //     color: '#FF4961',
            //   },
            // },
            labels: {
              style: {
                colors: '#FF4961'
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return customSharedTooltip(
                series,
                seriesIndex,
                dataPointIndex,
                w,
            )
          }
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const displayDataLabel = (serieIndex, index) => {
      let statusContent = props.acquisitionIndicators.chart[labels.value[index]]

      if (serieIndex == 0) {
        if (index == 0) {
          return statusContent.newWF.value + ' ' +
              statusContent.newWF.comparativePeriodSymbol
        } else {
          return statusContent.newWF.value + ' ' +
              statusContent.newWF.comparativePeriodSymbol + ' (' +
              percent(statusContent.newWF.comparativePreviousStatus, 0) + ')'
        }
      } else {
        if (index == 0) {
          return statusContent.cumulativeTime.value + ' ' +
              statusContent.cumulativeTime.comparativePeriodSymbol
        } else {

          return statusContent.cumulativeTime.value + ' ' +
              statusContent.cumulativeTime.comparativePeriodSymbol + ' (' +
              percent(statusContent.cumulativeTime.comparativePreviousStatus, 0) + ', ' +
              statusContent.cumulativeTime.comparativeTimePreviousStatus + ' ' +
              i18n.t('d') + ')'
        }
      }
    }

    const customSharedTooltip = (series, seriesIndex, dataPointIndex, w) => {
      // console.log(series)
      // console.log(seriesIndex)
      // console.log(dataPointIndex)
      // console.log(w)

      const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex]
      const hoverIndexes = w.globals.seriesX.map((seriesX) => {
        return seriesX.findIndex((xData) => xData === hoverXaxis)
      })

      let status = labels.value[dataPointIndex]
      let statusContent = props.acquisitionIndicators.chart[status]
      let hoverList = ''
      hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
        if (hoverIndex >= 0) {

          let value = seriesEachIndex == 0 ? statusContent.details.targetPeriod.value : statusContent.details.comparativePeriod.value
          let label = seriesEachIndex == 0 ? capitalize(i18n.t('targetPeriod')) : capitalize(i18n.t('comparativePeriod'))

          let extraContent = ``
          if (seriesEachIndex == 0) {
            let currentStatus = statusContent.details.targetPeriod.comparativePreviousStatus
            let previousStatus = statusContent.details.comparativePeriod.comparativePreviousStatus

            let currentTime = statusContent.details.targetPeriod.comparativeTimePreviousStatus
            let previousTime = statusContent.details.comparativePeriod.comparativeTimePreviousStatus

            extraContent = `
                                            <div>
                                                <div class="text-${getColor(currentStatus, previousStatus)}"><small>${percent(statusContent.details.targetPeriod.comparativePreviousStatus, 0)}</small></div>
                                                <div class="text-${getColor(currentTime, previousTime, true)}"><small>${statusContent.details.targetPeriod.comparativeTimePreviousStatus + ' ' + i18n.tc('day', statusContent.details.targetPeriod.comparativeTimePreviousStatus)}</small></div>
                                            </div>
          `
          } else {
            extraContent = `
                                            <div>
                                                <div><small>${percent(statusContent.details.comparativePeriod.comparativePreviousStatus, 0)}</small></div>
                                                <div><small>${statusContent.details.comparativePeriod.comparativeTimePreviousStatus + ' ' + i18n.tc('day', statusContent.details.comparativePeriod.comparativeTimePreviousStatus)}</small></div>
                                            </div>
          `
          }

          hoverList += `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
<!--                            <span class="apexcharts-tooltip-marker" style="rgba(130,134,139,0.12);"></span>-->
                            <div class="apexcharts-tooltip-text w-100" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group d-flex justify-content-between">
                                    <div class="apexcharts-tooltip-text-y-label my-auto">
                                        ${label}
                                    </div>
                                    <div class="apexcharts-tooltip-text-y-value w-100">
                                        <div class="d-flex justify-content-end">
                                            <div class="my-auto mr-50">${value}</div>
                                            ${extraContent}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>`
        }
      })

      let comparativePreviousStatus = statusContent.details.comparison.comparativePreviousStatus
      let comparativeTimePreviousStatus = statusContent.details.comparison.comparativeTimePreviousStatus
      let labelFinal = capitalize(i18n.t('comparison'))
      let extraContentFinal = `
                                            <div>
                                                <div class="text-${getColor(comparativePreviousStatus, null)}"><small>${percent(statusContent.details.comparison.comparativePreviousStatus, 0)}</small></div>
                                                <div class="text-${getColor(comparativeTimePreviousStatus, null, true)}"><small>${percent(statusContent.details.comparison.comparativeTimePreviousStatus, 0)}</small></div>
                                            </div>
          `
      hoverList += `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
<!--                            <span class="apexcharts-tooltip-marker" style="background-color: rgba(130,134,139,0.12);"></span>-->
                            <div class="apexcharts-tooltip-text w-100" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group d-flex justify-content-between">
                                    <div class="apexcharts-tooltip-text-y-label my-auto">
                                        ${labelFinal}
                                    </div>
                                    <div class="apexcharts-tooltip-text-y-value w-100">
                                        <div class="d-flex justify-content-end">
                                            <div class="my-auto mr-50">${percent(statusContent.details.comparison.value)}</div>
                                            ${extraContentFinal}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>`

      return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
          labels.value[dataPointIndex]
      }</div>${hoverList}`
    }

    const getColor = (current, previous, inverse=false) => {
      if (previous != null) {
        let variation = previous == 0 ? 0 : (((current - previous) / previous) * 100)
        if (Math.abs(variation) <= 2) {
          return 'warning'
        } else if (current > previous) {
          return inverse?'danger':'success'
        } else {
          return inverse?'success':'danger'
        }
      } else {
        if (Math.abs(current) <= 2) {
          return 'warning'
        } else if (current > 0) {
          return inverse?'danger':'success'
        } else {
          return inverse?'success':'danger'
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isReady,
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { percent, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>