<template>
  <div>
    <b-row>
      <b-col md="6">
        <collapse hover>
          <collapse-item
              :title="durationTitle"
              class="mt-0"
          >
            <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="configPeriod"
            />
          </collapse-item>
        </collapse>
      </b-col>

      <b-col md="6">
        <collapse hover>
          <collapse-item
              :title="comparisonDurationTitle"
              class="mt-0"
          >
            <flat-pickr
                v-model="comparisonRangeDate"
                class="form-control"
                :config="configComparisonPeriod"
            />
          </collapse-item>
        </collapse>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch }                              from '@vue/composition-api'
import { capitalize }                                        from '../../../../../utils/filter'
import Collapse                                              from '../../../../../components/collapse/Collapse.vue'
import CollapseItem                                          from '../../../../../components/collapse/CollapseItem.vue'
import i18n                                                  from '../../../../../libs/i18n'
import moment                                                from 'moment'
import { getCurrentFinancialYear, getPreviousFinancialYear } from '../../../../../utils/utils'
import flatPickr                                             from 'vue-flatpickr-component'
import { French as FrenchLocale }                            from 'flatpickr/dist/l10n/fr.js'
import { English as EnglishLocale }                          from 'flatpickr/dist/l10n/default.js'

export default {
  components: { CollapseItem, Collapse, flatPickr },
  props: {
    durationStart: {
      type: String,
    },
    durationEnd: {
      type: String,
    },
    comparisonDurationStart: {
      type: String,
    },
    comparisonDurationEnd: {
      type: String,
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const rangeDate = ref(null)
    const comparisonRangeDate = ref(null)
    const durationTitle = ref('')
    const comparisonDurationTitle = ref('')
    const configPeriod = ref({
      locale: i18n.locale == 'fr' ? FrenchLocale : EnglishLocale,
      altFormat: i18n.locale == 'fr' ? 'j F Y' : 'Y-m-d',
      altInput: true,
      allowInput: true,
      mode: 'range',
      defaultDate: [props.durationStart, props.durationEnd],
      onChange: function (selectedDates, datestr) {
        if (selectedDates.length == 2) {
          emit('updateDates', {
            start: moment(selectedDates[0]).format('YYYY-MM-DD'),
            end: moment(selectedDates[1]).format('YYYY-MM-DD'),
            comparisonStart: props.comparisonDurationStart,
            comparisonEnd: props.comparisonDurationEnd,
          })

          updateDurationTitle({
            start: moment(selectedDates[0]).format('YYYY-MM-DD'),
            end: moment(selectedDates[1]).format('YYYY-MM-DD')
          })
        }
      }
    })
    const configComparisonPeriod = ref({
      locale: i18n.locale == 'fr' ? FrenchLocale : EnglishLocale,
      altFormat: i18n.locale == 'fr' ? 'j F Y' : 'Y-m-d',
      altInput: true,
      allowInput: true,
      mode: 'range',
      defaultDate: [props.comparisonDurationStart, props.comparisonDurationEnd],
      onChange: function (selectedDates, datestr) {
        if (selectedDates.length == 2) {

          emit('updateDates', {
            start: props.durationStart,
            end: props.durationEnd,
            comparisonStart: moment(selectedDates[0]).format('YYYY-MM-DD'),
            comparisonEnd: moment(selectedDates[1]).format('YYYY-MM-DD'),
          })

          updateComparisonDurationTitle({
            start: moment(selectedDates[0]).format('YYYY-MM-DD'),
            end: moment(selectedDates[1]).format('YYYY-MM-DD'),
          })
        }
      }
    })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateDurationTitle = ({ start, end }) => {
      let currentFinancialYear = getCurrentFinancialYear()
      let output = capitalize(i18n.t('duration:')) + ' '

      if (
          start == currentFinancialYear.start &&
          end == currentFinancialYear.end
      ) {
        durationTitle.value = output + i18n.t('thisFinancialYear')
      } else {
        // durationTitle.value = output + i18n.t('from') + ' ' +
        //     moment(start).format('D MMMM YYYY') + ' ' +
        //     i18n.t('to') + ' ' +
        //     moment(end).format('D MMMM YYYY')
        durationTitle.value = output + i18n.t('from') + ' ' +
            moment(start).format('DD/MM/YYYY') + ' ' +
            i18n.t('to') + ' ' +
            moment(end).format('DD/MM/YYYY')
      }
    }
    const updateComparisonDurationTitle = ({ start, end }) => {
      let previousFinancialYear = getPreviousFinancialYear()
      let output = capitalize(i18n.t('comparison:')) + ' '

      if (
          start == previousFinancialYear.start &&
          end == previousFinancialYear.end
      ) {
        comparisonDurationTitle.value = output + i18n.t('previousFinancialYear')
      } else {
        // durationTitle.value = output + i18n.t('from') + ' ' +
        //     moment(start).format('D MMMM YYYY') + ' ' +
        //     i18n.t('to') + ' ' +
        //     moment(end).format('D MMMM YYYY')
        comparisonDurationTitle.value = output + i18n.t('from') + ' ' +
            moment(start).format('DD/MM/YYYY') + ' ' +
            i18n.t('to') + ' ' +
            moment(end).format('DD/MM/YYYY')
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updateDurationTitle({
      start: props.durationStart,
      end: props.durationEnd
    })
    updateComparisonDurationTitle({
      start: props.comparisonDurationStart,
      end: props.comparisonDurationEnd
    })

    return {
      // Components

      // Data
      rangeDate,
      comparisonRangeDate,
      durationTitle,
      comparisonDurationTitle,
      configPeriod,
      configComparisonPeriod,

      // Computed
      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
//@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>