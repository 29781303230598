<template>
  <div>
    <b-overlay
        :show="!isReady"
        rounded="sm"
    >

      <div v-if="isReady">

        <div>
          <b>{{
              capitalize($t('totalNumberOfActions', { number: actionsIndicator.numberOfActions }))
             }}
          </b>
        </div>
        <div>
          <b>{{
              capitalize($t('totalSpentTime', { number: moment.duration(actionsIndicator.pastTime, 'minutes').humanize() }))
             }}
          </b>
        </div>

        <vue-apex-charts
            type="line"
            height="350"
            class="mt-1"
            :options="chartOptions"
            :series="series"
        ></vue-apex-charts>

      </div>
    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import IndicatorEvolution       from '../../../../../components/indicatorEvolution/IndicatorEvolution.vue'
import VueApexCharts            from 'vue-apexcharts'
import { capitalize, percent }  from '../../../../../utils/filter'

import moment from 'moment'
import i18n   from '../../../../../libs/i18n'

export default {
  components: { VueApexCharts },
  props: {
    actionsIndicator: {
      type: Object,
      default: () => {}
    },
    isReady: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const series = computed(() => {
      let actions = []
      let times = []
      for (const status in props.actionsIndicator.chart) {
        actions.push(props.actionsIndicator.chart[status].targetPeriod.value)
        times.push(props.actionsIndicator.chart[status].pastTime.value)
      }

      return [{
        name: capitalize(i18n.tc('action', 2)),
        type: 'bar',
        data: actions
      }, {
        name: capitalize(i18n.t('time')),
        type: 'line',
        data: times
      }]

    })

    const labels = computed(() => {
      return Object.keys(props.actionsIndicator.chart)
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0
          }
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        colors: ['#7367F029', '#FF4961BF'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            // console.log(val, opts)
            return displayDataLabel(opts.seriesIndex, opts.dataPointIndex)
            // return val + '%'
          },
          offsetY: -10,
          style: {
            fontSize: '12px',
            colors: ['#7367F0', '#FF4961']
          },
          background: {
            enabled: false,
          }

        },
        labels: labels.value,
        markers: {
          size: 0
        },
        // xaxis: {
        //   type: 'datetime'
        // },
        yaxis: [
          {
            seriesName: 'TEAM A',
            // title: {
            //   text: 'TEAM A',
            //   style: {
            //     color: '#7367F0',
            //   },
            // },
            labels: {
              style: {
                colors: '#7367F0'
              }
            }
          },
          {
            seriesName: 'TEAM B',
            opposite: true,
            // title: {
            //   text: 'TEAM B',
            //   style: {
            //     color: '#FF4961',
            //   },
            // },
            labels: {
              style: {
                colors: '#FF4961'
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return customSharedTooltip(
                series,
                seriesIndex,
                dataPointIndex,
                w,
            )
          }
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const displayDataLabel = (serieIndex, index) => {
      if (serieIndex == 0) {
        let targetPeriod = props.actionsIndicator.chart[labels.value[index]].targetPeriod
        return targetPeriod.value
      } else {
        let pastTime = props.actionsIndicator.chart[labels.value[index]].pastTime
        return moment.duration(pastTime.value, 'minutes').humanize()
      }
    }

    const customSharedTooltip = (series, seriesIndex, dataPointIndex, w) => {
      // console.log(series)
      // console.log(seriesIndex)
      // console.log(dataPointIndex)
      // console.log(w)

      const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex]
      const hoverIndexes = w.globals.seriesX.map((seriesX) => {
        return seriesX.findIndex((xData) => xData === hoverXaxis)
      })

      let hoverList = ''
      hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
        if (hoverIndex >= 0) {
          hoverList += `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: ${
              w.globals.markers.colors[seriesEachIndex]
          };"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">${
              w.globals.seriesNames[seriesEachIndex]
          }: </span>`

          if (seriesEachIndex == 0) {
            hoverList += `<span class="apexcharts-tooltip-text-y-value">${w.globals.yLabelFormatters[0](
                series[seriesEachIndex][hoverIndex]
            )}</span>`
          } else {
            hoverList += `<span class="apexcharts-tooltip-text-y-value">${Math.round(moment.duration(w.globals.yLabelFormatters[0](
                series[seriesEachIndex][hoverIndex]
            ), 'minutes').asHours()) + ' ' + i18n.tc('hour', w.globals.yLabelFormatters[0](series[seriesEachIndex][hoverIndex]))}</span>`
          }

          hoverList += `</div>
                            </div>
                        </div>`
        }
      })
      return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
          labels.value[dataPointIndex]
      }</div>${hoverList}`
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,

      // Data

      // Computed
      series,
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>