import { render, staticRenderFns } from "./IndicatorChoice.vue?vue&type=template&id=315e4e54&scoped=true&"
import script from "./IndicatorChoice.vue?vue&type=script&lang=js&"
export * from "./IndicatorChoice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315e4e54",
  null
  
)

export default component.exports