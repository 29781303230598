<template>
  <div>
    <b-card
        :title="capitalize($t('workFlosMonitoring'))"
        :sub-title="capitalize($t('daysRemainingBeforeMonitorings'))"
    >
      <b-overlay
          :show="!isReady"
          rounded="sm"
      >
        <div v-if="isReady">

          <work-flo-next-action-indicator
              v-for="(workFlo, index) in displayedWorkFlos"
              :key="'wf_monitoring_'+index"
              :work-flo="workFlo"
          />

          <b-pagination
              v-model="currentPage"
              :total-rows="workFlosMonitoring.length"
              :per-page="perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <icon icon="chevron-left"/>
            </template>
            <template #next-text>
              <icon icon="chevron-right"/>
            </template>
          </b-pagination>
        </div>

      </b-overlay>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../utils/filter'

import WorkFloNextActionIndicator from '../../../../../components/card/WorkFloNextActionIndicator.vue'

export default {
  components: {
    WorkFloNextActionIndicator
  },
  props: {
    workFlosMonitoring: {
      type: Array,
      default: () => []
    },
    isReady: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentPage = ref(1)
    const perPage = ref(5)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const displayedWorkFlos = computed(() => {
      return props.workFlosMonitoring.slice((currentPage.value - 1) * perPage.value, currentPage.value * perPage.value)
    })

    // const filteredWorkFlos = computed(() => {
    //   let withoutNextAction = props.workFlosMonitoring.filter(w=>w._history["nextAction"] == null)
    //
    //   return props.workFlosMonitoring.filter(w=>w._history["nextAction"] != null).concat(...withoutNextAction)
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data
      currentPage,
      perPage,

      // Computed
      displayedWorkFlos,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>