<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="mb-0">{{ capitalize($t('mainIndicators')) }}</h4>
        <div class="text-secondary">
          <i>
            <small>{{ capitalize($t('sinceLastWeek')) }}</small>
          </i>
        </div>
      </template>

      <b-row>
        <b-col md="2">
          <b-media
              v-if="isReady"
              no-body
              class="mb-1 mb-md-0 cursor-pointer"
              @mouseover="isWorkFloHover = true"
              @mouseleave="isWorkFloHover = false"
              :class="{'bg-primary bg-lighten-5 rounded-lg':(isWorkFloHover||localIndicatorChoice=='acquisition')}"
          >
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-success"
              >
                <icon
                    icon="link"
                    size="lg"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="d-flex">
                <h4 class="font-weight-bolder mb-0 mr-50">
                  {{ mainIndicators.workFlos.value }}
                </h4>
                <b-badge
                    pill
                    :variant="getBadgeColor(mainIndicators.workFlos.difference)"
                    class="my-auto"
                >
                  {{ displayNumberWithSymbol(mainIndicators.workFlos.difference) }}
                </b-badge>
              </div>
              <b-card-text class="font-small-3 mb-0">
                {{ capitalize($t('workFlos')) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="2">
          <b-media
              v-if="isReady"
              no-body
              class="mb-1 mb-md-0"
          >
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-info"
              >
                <icon
                    icon="users"
                    size="lg"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="d-flex">
                <h4 class="font-weight-bolder mb-0 mr-50">
                  {{ mainIndicators.customers.value }}
                </h4>
                <b-badge
                    pill
                    :variant="getBadgeColor(mainIndicators.customers.difference)"
                    class="my-auto"
                >
                  {{ displayNumberWithSymbol(mainIndicators.customers.difference) }}
                </b-badge>
              </div>
              <b-card-text class="font-small-3 mb-0">
                {{ capitalize($t('customers')) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="2">
          <b-media
              v-if="isReady"
              no-body
              class="mb-1 mb-md-0"
          >
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-warning"
              >
                <icon
                    icon="shopping-cart"
                    size="lg"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="d-flex">
                <h4 class="font-weight-bolder mb-0 mr-50">
                  {{ mainIndicators.orderedProducts.value }}
                </h4>
                <b-badge
                    pill
                    :variant="getBadgeColor(mainIndicators.orderedProducts.difference)"
                    class="my-auto"
                >
                  {{ displayNumberWithSymbol(mainIndicators.orderedProducts.difference) }}
                </b-badge>
              </div>
              <b-card-text class="font-small-3 mb-0">
                {{ capitalize($tc('product', 2)) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="2">
          <b-media
              v-if="isReady"
              no-body
              class="mb-1 mb-md-0"
          >
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-secondary"
              >
                <icon
                    icon="road"
                    size="lg"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="d-flex">
                <h4 class="font-weight-bolder mb-0 mr-50">
                  {{ mainIndicators.interventions.value }}
                </h4>
                <b-badge
                    pill
                    :variant="getBadgeColor(mainIndicators.interventions.difference)"
                    class="my-auto"
                >
                  {{ displayNumberWithSymbol(mainIndicators.interventions.difference) }}
                </b-badge>
              </div>
              <b-card-text class="font-small-3 mb-0">
                {{ capitalize($tc('intervention', 2)) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="2">
          <b-media
              v-if="isReady"
              no-body
              class="mb-1 mb-md-0"
          >
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-primary"
              >
                <icon
                    icon="chart-pie"
                    size="lg"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="d-flex">
                <h4 class="font-weight-bolder mb-0 mr-50">
                  {{ currency(mainIndicators.turnover.value, true) }}
                </h4>
                <b-badge
                    v-if="mainIndicators.turnover.difference >0"
                    pill
                    variant="light-success"
                    class="my-auto"
                >+{{ currency(mainIndicators.turnover.difference, true) }}
                </b-badge>
                <b-badge
                    v-else
                    pill
                    variant="light-danger"
                    class="my-auto"
                >
                  <span v-if="mainIndicators.turnover.difference != 0">-</span>
                  {{ currency(mainIndicators.turnover.difference, true) }}
                </b-badge>
              </div>
              <b-card-text class="font-small-3 mb-0">
                {{ capitalize($t('turnover')) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="2">
          <b-media
              v-if="isReady"
              no-body
          >
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-danger"
              >
                <icon
                    icon="euro-sign"
                    size="lg"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="d-flex">
                <h4 class="font-weight-bolder mb-0 mr-50">
                  {{ currency(mainIndicators.expenses.value, true) }}
                </h4>
                <b-badge
                    v-if="mainIndicators.expenses.difference >0"
                    pill
                    variant="light-danger"
                    class="my-auto"
                >+{{ currency(mainIndicators.expenses.difference, true) }}
                </b-badge>
                <b-badge
                    v-else
                    pill
                    variant="light-success"
                    class="my-auto"
                >
                  <span v-if="mainIndicators.expenses.difference != 0">-</span>
                  {{ currency(mainIndicators.expenses.difference, true) }}
                </b-badge>
              </div>
              <h4 class="font-weight-bolder mb-0">
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ capitalize($t('expenses')) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>


      </b-row>


    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }                          from '@vue/composition-api'
import { isEmptyObject }                                 from '../../../../../utils/utils'
import { capitalize, currency, displayNumberWithSymbol } from '../../../../../utils/filter'

export default {
  components: {},
  props: {
    mainIndicators: {
      type: Object,
      default: () => {}
    },
    indicatorChoice: {
      type: String,
      default: 'indicatorChoice'
    }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isWorkFloHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.mainIndicators)
    })

    const localIndicatorChoice = computed({
      get () {
        return props.indicatorChoice
      },
      set (val) {
        emit('update:indicatorChoice', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getBadgeColor = (number) => {
      return number > 0 ? 'light-success' : 'light-danger'
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isWorkFloHover,

      // Computed
      isReady,
      localIndicatorChoice,

      // Methods
      getBadgeColor,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { displayNumberWithSymbol, currency, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>